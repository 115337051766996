import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { FieldSelect } from 'components/Fields'
import { Grid, makeStyles } from '@material-ui/core'
import * as yup from 'yup'
import { validateFormValues } from 'utils/forms'
import Button from 'components/Button'
import { getIdiomas } from 'store/modules/idiomas/idiomas.selectors'
import { callRelatorioAuditoria, callRelatorioSumario } from 'store/modules/relatorios/auditoria'
import {
  getIsCarregandoRelatorioAuditoria,
  getIsCarregandoRelatorioSumario,
} from 'store/modules/relatorios/auditoria/auditoria.selectors'
import moment from 'moment'
import { getEmpresaUsuario } from 'store/modules/auth/auth.selectors'
import SurveyLineIcon from 'remixicon-react/SurveyLineIcon'
import AwardLineIcon from 'remixicon-react/AwardLineIcon'
import { map, range } from 'lodash'
import { useConfirmationDialog } from 'components/Dialog/ConfirmDialog'
import Card from './Card'

const useStyles = makeStyles((theme) => ({
  submitButton: {
    marginTop: theme.spacing(3),
  },
}))

const Auditoria = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const idiomas = useSelector(getIdiomas)
  const loadingRelatorioAuditoria = useSelector(getIsCarregandoRelatorioAuditoria)
  const loadingRelatorioSumario = useSelector(getIsCarregandoRelatorioSumario)
  const dialog = useConfirmationDialog()

  const empresa = useSelector(getEmpresaUsuario)

  const RELATORIOS = [
    {
      title: t('relatorios.relatarioAuditoria'),
      action: callRelatorioAuditoria,
      loading: loadingRelatorioAuditoria,
      icon: SurveyLineIcon,
      type: 'mail',
    },
    {
      title: t('relatorios.sumarioPublico'),
      action: callRelatorioSumario,
      loading: loadingRelatorioSumario,
      icon: AwardLineIcon,
    },
  ]

  const anoAtual = +moment().format('YYYY')
  const anos = map(range(2014, anoAtual + 2), (ano) => ({
    value: ano.toString(), label: `${(ano - 1).toString()}/${ano.toString().slice(2)}`,
  }))

  return (
    <Grid spacing={4} container>
      {RELATORIOS.map(({ action, title, icon, loading, type }, index) => (
        <Grid item xs={12} md={4} key={index}>
          <Form
            validate={validateFormValues(yup.object({
              anoBase: yup.string().required(),
              idioma: yup.number().required(),
            }))}
            onSubmit={(values) => {
              if (type === 'mail') {
                dialog.showConfirmation({
                  title: t('dialogs.relatoriosEmGeracaoTitulo'),
                  message: t('dialogs.relatoriosEmGeracao'),
                }).then((result) => {
                  if (result) {
                    dispatch(action({
                      ...values,
                      empresaId: empresa.id,
                    }))
                  }
                })
              } else {
                dispatch(action({
                  ...values,
                  empresaId: empresa.id,
                }))
              }
            }}
            render={({ handleSubmit, submitting, values }) => (
              <form onSubmit={handleSubmit}>
                <Card color="orange" title={title} icon={icon}>
                  <Grid spacing={1} justifyContent="center" container>
                    <Grid item xs={6}>
                      <FieldSelect
                        required
                        name="anoBase"
                        label={t('inputs.anoBase')}
                        options={anos}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FieldSelect
                        required
                        name="idioma"
                        label={t('inputs.idioma')}
                        options={idiomas.map((idioma) => ({
                          label: t(idioma.label),
                          value: idioma.id,
                        }))}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        loading={loading}
                        loadingText="inputs.gerandoRelatorio"
                        type="submit"
                        className={classes.submitButton}
                        disabled={!values.anoBase || submitting}
                      >
                        {t('relatorios.downloadRelatorio')}
                      </Button>
                    </Grid>
                  </Grid>
                </Card>
              </form>
            )}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default Auditoria
