import { combineEpics, ofType } from 'redux-observable'
import { map, filter, debounceTime, mergeMap } from 'rxjs/operators'
import { push, LOCATION_CHANGE } from 'connected-react-router'
import { getEmpresaAuditoriaSelecionada, getAnoSelecionado } from 'store/modules/auditor/auditor.selectors'
import {
  LISTAR_DADOS_AUDITORIA,
  SALVAR_DADOS_AUDITORIA,
  SALVAR_PARECER_PRESSAO,
  SALVAR_PARECER_DESEMPENHO,
  SALVAR_PARECER_INDICADOR,
  LISTAR_AUDITORES_EMPRESA,
  SALVAR_EDITAR_AUDITOR,
} from 'store/modules/auditor/auditor.actions'
import {
  apiListarDadosAuditoria,
  apiSalvarDadosAuditoria,
  apiSalvarParecerPressao,
  apiSalvarParecerDesempenho,
  apiSalvarParecerIndicador,
  apiListarDadosEmpresaParaAuditoria,
  apiListarAuditoresEmpresa,
  apiSalvarEditarAuditor,
} from 'store/modules/api/auditor.actions'

const validarEmpresaSelecionada = (action$, state$) => action$.pipe(
  ofType(LOCATION_CHANGE),
  filter(({ payload }) => !getEmpresaAuditoriaSelecionada(state$.value) &&
    (payload.location.pathname === '/auditoria/dadosGerais' ||
    payload.location.pathname === '/auditoria/meuPerfil' ||
    payload.location.pathname === '/auditoria/empresa' ||
    payload.location.pathname === '/auditoria/relatorios' ||
    payload.location.pathname === '/auditoria/planoAuditoria' ||
    payload.location.pathname === '/auditoria/pareceres' ||
    payload.location.pathname === '/auditoria/conclusao')),
  map(() => push('/auditoria')),
)

const onListarDadosAuditoria = (action$, state$) => action$.pipe(
  ofType(LISTAR_DADOS_AUDITORIA),
  debounceTime(250),
  mergeMap(({ payload }) => [
    apiListarDadosAuditoria(payload),
    apiListarDadosEmpresaParaAuditoria({
      empresaId: payload.empresaId, ano: getAnoSelecionado(state$.value),
    }),
  ]),
)

const onCallSalvarDadosAuditoria = (action$) => action$.pipe(
  ofType(SALVAR_DADOS_AUDITORIA),
  debounceTime(250),
  map(({ payload }) => apiSalvarDadosAuditoria(payload)),
)

const onCallSalvarParecerPressao = (action$) => action$.pipe(
  ofType(SALVAR_PARECER_PRESSAO),
  debounceTime(250),
  map(({ payload }) => apiSalvarParecerPressao(payload)),
)

const onCallSalvarParecerDesempenho = (action$) => action$.pipe(
  ofType(SALVAR_PARECER_DESEMPENHO),
  debounceTime(250),
  map(({ payload }) => apiSalvarParecerDesempenho(payload)),
)

const onCallSalvarParecerIndicador = (action$) => action$.pipe(
  ofType(SALVAR_PARECER_INDICADOR),
  debounceTime(250),
  map(({ payload }) => apiSalvarParecerIndicador(payload)),
)

const onCallListarAuditoresEmpresa = (action$) => action$.pipe(
  ofType(LISTAR_AUDITORES_EMPRESA),
  debounceTime(250),
  map(({ payload }) => apiListarAuditoresEmpresa(payload)),
)

const onCallSalvarEditarAuditor = (action$) => action$.pipe(
  ofType(SALVAR_EDITAR_AUDITOR),
  debounceTime(250),
  map(({ payload }) => apiSalvarEditarAuditor(payload)),
)

export default combineEpics(
  validarEmpresaSelecionada,
  onListarDadosAuditoria,
  onCallSalvarDadosAuditoria,
  onCallSalvarParecerPressao,
  onCallSalvarParecerDesempenho,
  onCallSalvarParecerIndicador,
  onCallListarAuditoresEmpresa,
  onCallSalvarEditarAuditor,
)
