import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import * as yup from 'yup'
import Button from 'components/Button'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { validateFormValues } from 'utils/forms'
import { FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, Typography } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { FieldText, FieldSelect } from 'components/Fields'
import { getMoedas } from 'store/modules/moedas/moedas.selectors'
import { getIdiomas } from 'store/modules/idiomas/idiomas.selectors'
import { useConfirmationDialog } from 'components/Dialog/ConfirmDialog'
import { getPaises, getPaisSelecionado } from 'store/modules/paises/paises.selectors'
import { callAtualizarPais, callCriarPais, callExcluirPais } from 'store/modules/paises'
import { getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'
import { TIPO_USUARIO } from 'utils/constantes'
import { enqueueSnackbar } from 'store/modules/notifications'

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey.main}`,
  },
  screenTitle: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(5),
    fontSize: theme.typography.pxToRem(24),
  },
  form: {
    marginTop: theme.spacing(4),
  },
  footer: {
    marginTop: theme.spacing(6),
  },
}))

const PaisRegiao = ({ onCancel, onCreate }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const dialog = useConfirmationDialog()

  const moedas = useSelector(getMoedas)
  const idiomas = useSelector(getIdiomas)
  const paisEdit = useSelector(getPaisSelecionado)
  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)

  const isEdit = paisEdit?.id != null

  const desabilitarFormulario = tipoUsuarioLogado === TIPO_USUARIO.ADMIN.OBSERVADOR

  const listarPaises = useSelector(getPaises)

  const [formInitialValues, setFormInitialValues] = useState(paisEdit)
  const [paisCopia, setPaisCopia] = useState()

  const handleSave = (values) => {
    if (isEdit) {
      return dispatch(callAtualizarPais({ id: paisEdit.id, payload: values }))
    }

    let payload = values

    if (paisCopia) {
      payload = {
        ...payload,
        paisId: paisCopia.id
      }
    }

    dispatch(callCriarPais(payload))

    if (paisCopia) {
      dispatch(enqueueSnackbar({
        message: t('paisesCalculos.alertaCopiandoPais'),
        options: { variant: 'info', autoHideDuration: 3000, dismissible: true, },
      }))
      setPaisCopia(undefined)
    }

    return onCreate()
  }

  const handleDelete = (pais) => {
    dialog.showConfirmation({
      title: t('dialogs.confirmarExclusao'),
      message: t('dialogs.excluirPaisRegiao', {
        pais,
      }),
    }).then((result) => result && dispatch(callExcluirPais({ id: paisEdit.id })))
  }

  const handleCopyCountry = (event) => {
    const paisId = event.target.value
    const pais = listarPaises.find((p) => p.id === parseInt(paisId, 10))

    setPaisCopia(pais)
    setFormInitialValues(pais)

    dispatch({
      type: 'SET_PAIS_SELECIONADO',
      payload: { ...pais }
    })
  }

  const handleAction = () => (isEdit ? onCancel() : onCreate())

  return (
    <>
      <div className={classes.sectionTitle}>
        <Typography className={classes.screenTitle}>
          {t('inputs.adicionarPaisRegiao')}
        </Typography>
        {tipoUsuarioLogado !== TIPO_USUARIO.ADMIN.OBSERVADOR && isEdit && (
          <Button
            onClick={() => handleDelete(paisEdit?.nome)}
            variant="outlined"
          >
            {t('paisesCalculos.excluirPaisRegiao')}
          </Button>
        )}
      </div>

      {!isEdit && (
        <FormControl fullWidth>
          <InputLabel id="select-pais-label">Selecione um país para clonar os dados</InputLabel>
          <Select
            labelId="select-pais-label"
            value={paisCopia?.id ?? ''}
            onChange={handleCopyCountry}
          >
            {listarPaises.map((pais) => (
              <MenuItem key={pais.id} value={String(pais.id)}>
                {pais.nome}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <Form
        initialValues={{
          nome: formInitialValues?.nome || '',
          moedaId: formInitialValues?.moedaId || '',
          idiomaId: formInitialValues?.idiomaId || null,
          comentarios: formInitialValues?.comentarios || '',
          linkEcorregioesTerrestres: formInitialValues?.linkEcorregioesTerrestres || '',
          linkEcorregioesMarinhas: formInitialValues?.linkEcorregioesMarinhas || '',
          linkRegioesHidrograficas: formInitialValues?.linkRegioesHidrograficas || '',
        }}
        onSubmit={handleSave}
        validate={validateFormValues(yup.object().shape({
          nome: yup.string().required(),
          idiomaId: yup.number().nullable().required(),
          moedaId: yup.number().nullable().required(),
          comentarios: yup.string(),
          linkEcorregioesTerrestres: yup.string().url(),
          linkEcorregioesMarinhas: yup.string().url(),
          linkRegioesHidrograficas: yup.string().url(),
        }))}
        render={({ handleSubmit, pristine, submitting }) => (
          <form className={classes.form} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid xs={12} item>
                <FieldText
                  required
                  name="nome"
                  label={t('paisesCalculos.nomePaisRegiao')}
                  disabled={desabilitarFormulario}
                />
              </Grid>
              <Grid xs={12} item>
                <FieldSelect
                  required
                  options={(idiomas || []).map((idioma) => ({
                    label: t(idioma.label),
                    value: idioma.id,
                  }))}
                  name="idiomaId"
                  label={t('paisesCalculos.idiomaPadrao')}
                  disabled={desabilitarFormulario}
                />
              </Grid>
              <Grid xs={12} item>
                <FieldSelect
                  required
                  name="moedaId"
                  label={t('paisesCalculos.moedaPadrao')}
                  options={(moedas || []).map((currency) => ({
                    label: t(`${currency.nome}`),
                    value: currency.id,
                  }))}
                  disabled={desabilitarFormulario}
                />
              </Grid>
              <Grid xs={12} item>
                <FieldText
                  name="linkEcorregioesTerrestres"
                  label={t('paisesCalculos.linkEcorregioesTerrestres')}
                  disabled={desabilitarFormulario}
                />
              </Grid>
              <Grid xs={12} item>
                <FieldText
                  name="linkEcorregioesMarinhas"
                  label={t('paisesCalculos.linkEcorregioesMarinhas')}
                  disabled={desabilitarFormulario}
                />
              </Grid>
              <Grid xs={12} item>
                <FieldText
                  name="linkRegioesHidrograficas"
                  label={t('paisesCalculos.linkRegioesHidrograficas')}
                  disabled={desabilitarFormulario}
                />
              </Grid>
              <Grid xs={12} item>
                <FieldText
                  multiline
                  minRows={5}
                  name="comentarios"
                  label={t('paisesCalculos.comentariosAdicionais')}
                  disabled={desabilitarFormulario}
                />
              </Grid>
            </Grid>
            {tipoUsuarioLogado !== TIPO_USUARIO.ADMIN.OBSERVADOR && (
              <Grid
                container
                justifyContent="space-between"
                className={clsx(isEdit ? classes.form : classes.footer)}
              >
                <Grid item>
                  <Button variant="danger" onClick={() => handleAction()}>
                    {t('inputs.cancelar')}
                  </Button>
                </Grid>

                <Grid item>
                  <Button disabled={isEdit && (pristine || submitting)} type="submit">
                    {t('inputs.salvar')}
                  </Button>
                </Grid>
              </Grid>
            )}
          </form>
        )}
      />
    </>
  )
}

PaisRegiao.propTypes = {
  onCancel: PropTypes.func,
  onCreate: PropTypes.func,
}

export default PaisRegiao
