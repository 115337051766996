import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ContentBox from 'components/ContentBox'
import { FormControl, InputLabel, makeStyles, MenuItem, OutlinedInput as MuiOutlinedInput, Select, Typography, withStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import Button from 'components/Button'
import { useSelector, useDispatch } from 'react-redux'
import ArrowLeftSLineIcon from 'remixicon-react/ArrowLeftSLineIcon'
import moment from 'moment'
import clsx from 'clsx'
import { map, range } from 'lodash'
import { getEmpresaAuditoriaSelecionada, getDadosAuditoriaSelecionada, getAnoSelecionado } from 'store/modules/auditor/auditor.selectors'
import { callListarDadosAuditoria, changeAnoSelecionado } from 'store/modules/auditor'
import { push } from 'connected-react-router'

const useStyles = makeStyles((theme) => ({
  contentBox: {
    padding: theme.spacing(2),
  },
  header: {
    display: 'flex',
    alignItems: 'flex-start',
    borderBottom: `1px solid ${theme.palette.grey.main}`,
  },
  headerButton: {
    fontSize: 14,
    display: 'flex',
    position: 'relative',
    textDecoration: 'none',
    justifyContent: 'center',
    textTransform: 'uppercase',
    marginRight: theme.spacing(3),
    color: theme.palette.grey.blue,
    padding: theme.spacing('6px', 2, '21px'),
    '&::after': {
      bottom: 0,
      opacity: 0,
      width: '65%',
      content: '""',
      display: 'block',
      position: 'absolute',
      height: theme.spacing(0.5),
      borderRadius: theme.spacing(4, 4, 0, 0),
      backgroundColor: theme.palette.blue.main,
      transition: theme.transitions.create('opacity'),
    },
  },
  headerButtonActive: {
    color: theme.palette.blue.main,
    '&::after': {
      opacity: 1,
    },
  },
  headerDisabled: {
    pointerEvents: 'none',
    opacity: 0.5,
  },
  contentHeader: {
    display: 'flex',
    columnGap: '25px',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '48px',
  },
  contentTitle: {
    display: 'flex',
    columnGap: '25px',
    alignItems: 'center',
  },
  title: {
    fontSize: 32,
    flexShrink: 0,
    color: theme.palette.common.white,
  },
  formControl: {
    height: 60,
    width: 260,
    marginLeft: theme.spacing(1),
    '& .MuiFormLabel-root': {
      color: theme.palette.white.main,
      fontSize: theme.typography.pxToRem(14),
    },
    '& .MuiInputBase-root:hover': {
      color: theme.palette.white.main,
    },
  },
  select: {
    color: theme.palette.white.main,
  },
  iconSelect: {
    color: theme.palette.white.main,
  },
}))

const OutlinedInput = withStyles((theme) => ({
  root: {
    '& $notchedOutline': {
      borderColor: theme.palette.white.main,
    },
    '&:hover $notchedOutline': {
      borderColor: theme.palette.white.main,
    },
    '&$focused $notchedOutline': {
      borderColor: theme.palette.white.main,
    },
  },
  focused: {},
  notchedOutline: {},
}))(MuiOutlinedInput)

const anoAtual = +moment().format('YYYY')
const anos = map(range(2014, anoAtual + 2), (ano) => ({
  value: ano.toString(), label: `${(ano - 1).toString()}/${ano.toString().slice(2)}`,
}))

const AuditorEmpresaLayout = ({ renderChildren }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const empresaSelecionada = useSelector(getEmpresaAuditoriaSelecionada)
  const dadosAuditoria = useSelector(getDadosAuditoriaSelecionada)
  const anoSelecionadoReducer = useSelector(getAnoSelecionado)

  const [anoSelecionado, setAnoSelecionado] = useState(anoSelecionadoReducer || anoAtual.toString())

  useEffect(() => {
    dispatch(callListarDadosAuditoria({
      empresaId: empresaSelecionada.id, ano: anoSelecionado,
    }))
  }, [anoSelecionado, dispatch, empresaSelecionada.id])

  const handleChangeAno = (event) => {
    setAnoSelecionado(event.target.value)
    dispatch(changeAnoSelecionado(event.target.value))

    dispatch(push('/auditoria/dadosGerais'))
  }

  const handleClick = (e) => {
    if (!dadosAuditoria?.id) e.preventDefault()
  }

  const navigationItems = [
    { label: 'auditoriaEmpresa.dadosGerais', to: '/auditoria/dadosGerais' },
    { label: 'auditoriaEmpresa.relatorios', to: '/auditoria/relatorios' },
    { label: 'auditoriaEmpresa.planoAuditoria', to: '/auditoria/planoAuditoria' },
    { label: 'auditoriaEmpresa.pareceres', to: '/auditoria/pareceres' },
    { label: 'auditoriaEmpresa.conclusao', to: '/auditoria/conclusao' },
  ]

  const controlTabs = (position) => {
    if (position === 0 && !dadosAuditoria?.id) {
      return clsx(classes.headerButton)
    }
    return clsx(classes.headerButton, !dadosAuditoria?.id && classes.headerDisabled)
  }

  return (
    <>
      <div className={classes.contentHeader}>
        <div className={classes.contentTitle}>
          <NavLink
            exact
            to="/auditorias"
            className={classes.linkButton}
          >
            <Button
              icon={ArrowLeftSLineIcon}
              variant="outlined"
            />
          </NavLink>
          <Typography className={classes.title} variant="h1">{empresaSelecionada.nome}</Typography>
        </div>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor="ano-base">
            {t('pressaoBiodiversidade.selecioneAno')}
          </InputLabel>
          <Select
            classes={{
              select: classes.select,
              icon: classes.iconSelect,
            }}
            value={anoSelecionado}
            onChange={handleChangeAno}
            label={t('pressaoBiodiversidade.selecioneAno')}
            inputProps={{
              name: 'ano-base',
              id: 'ano-base',
            }}
            input={<OutlinedInput label={t('pressaoBiodiversidade.selecioneAno')} />}
          >
            {map(anos, (option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <ContentBox className={classes.contentBox}>
        <div className={classes.header}>
          {navigationItems.map((item, index) => (
            <NavLink
              exact
              to={item.to}
              key={item.label}
              className={controlTabs(index)}
              activeClassName={classes.headerButtonActive}
              onClick={handleClick}
            >
              {t(item.label)}
            </NavLink>
          ))}
        </div>
        {renderChildren}
      </ContentBox>
    </>
  )
}

AuditorEmpresaLayout.propTypes = {
  renderChildren: PropTypes.object,
}

export default AuditorEmpresaLayout
