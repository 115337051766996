import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  Button as MuiButton,
  makeStyles,
  CircularProgress,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const getButtonColor = (theme, index) => (props) => {
  const color = theme.palette.buttons[props.variant]
  return color ? color[index] : theme.palette.buttons.default[index]
}

const useStyles = makeStyles((theme) => ({
  button: {
    fontSize: 14,
    minWidth: 40,
    borderRadius: 4,
    textTransform: 'initial',
    color: theme.palette.common.white,
    backgroundColor: getButtonColor(theme, 0),
    boxShadow: `0 1px 0 ${theme.palette.shadows.light}`,
    padding: (props) => {
      if (props.xSmall) return theme.spacing(0, 1)
      if (props.small) return theme.spacing(0.5, 1)
      if (props.hasIcon) return theme.spacing(1)
      return theme.spacing(1, 2)
    },
    '&:hover': {
      backgroundColor: getButtonColor(theme, 1),
    },
    '&:active': {
      backgroundColor: getButtonColor(theme, 2),
    },
    '&:disabled': {
      color: theme.palette.grey.A0,
      backgroundColor: theme.palette.grey.F4,
    },
  },
  buttonLink: {
    '&$button': {
      boxShadow: 'unset',
      backgroundColor: 'transparent',
      color: getButtonColor(theme, 0),
      padding: theme.spacing('2px', 0.5),
    },
    '&$button:hover': {
      textDecoration: 'underline',
      backgroundColor: theme.palette.grey.FA,
    },
    '&$button:active': {
      textDecoration: 'underline',
      color: theme.palette.common.white,
      backgroundColor: theme.palette.buttons.default[2],
    },
    '&$button:disabled': {
      color: theme.palette.grey.A0,
      backgroundColor: theme.palette.grey.F4,
    },
  },
  outlined: {
    '&$button': {
      color: theme.palette.primary.light,
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.grey.main}`,
      padding: (props) => {
        theme.spacing(props.small ? '3px' : '7px', props.small ? 1 : 2)
        if (props.small) return theme.spacing('3px', 1)
        if (props.hasIcon) return 7
        return '7px 15px'
      },
    },
    '&$button:hover': {
      backgroundColor: theme.palette.grey.FA,
    },
    '&$button:active': {
      backgroundColor: theme.palette.grey.F5,
    },
    '&$button:disabled': {
      color: theme.palette.grey.main,
    },
  },
  transparent: {
    '&$button': {
      color: theme.palette.primary.light,
      backgroundColor: 'transparent',
      boxShadow: 'unset',
      padding: theme.spacing('2px', 0.5),
    },
    '&$button:hover': {
      backgroundColor: theme.palette.grey.EA,
    },
    '&$button:active': {
      backgroundColor: theme.palette.grey.F5,
    },
    '&$button:disabled': {
      color: theme.palette.grey.main,
    },
  },

  startIcon: {
    width: 16,
    marginLeft: 0,
  },
}))

const Button = ({
  small,
  xSmall,
  link,
  variant,
  icon: Icon,
  startIcon: StartIcon,
  children,
  className,
  loading = false,
  loadingText = 'inputs.salvando',
  disabled,
  ...props
}) => {
  const { t } = useTranslation()
  const classes = useStyles({ small, xSmall, variant, hasIcon: !!Icon })

  const getChildren = () => {
    if (children && !loading) {
      return children
    } if (loading) {
      return t(loadingText)
    }
    return null
  }

  return (
    <MuiButton
      className={clsx(classes.button, classes[variant], className, { [classes.buttonLink]: link })}
      startIcon={StartIcon ? <StartIcon /> : null}
      endIcon={loading ? <CircularProgress size={20} /> : null}
      classes={{ startIcon: classes.startIcon }}
      disabled={disabled || loading}
      {...props}
    >
      {Icon && <Icon />}
      {getChildren()}
    </MuiButton>
  )
}

Button.propTypes = {
  small: PropTypes.bool,
  link: PropTypes.bool,
  variant: PropTypes.oneOf(['default', 'outlined', 'danger', 'green', 'transparent']),
  startIcon: PropTypes.object,
  icon: PropTypes.object,
  children: PropTypes.node,
  className: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  loadingText: PropTypes.string,
}

export default Button
