import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import * as yup from 'yup'
import { find } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Box, Link, makeStyles, MenuItem, Modal, Select, TextField, Typography } from '@material-ui/core'
import Button from 'components/Button'
import MainColumn from 'components/MainColumn'
import { FieldSelect, FieldText } from 'components/Fields'
import { validateFormValues } from 'utils/forms'
import Filters from 'components/FilterList/Filters'
import Pagination from 'components/Pagination'
import clsx from 'clsx'
import { useSelector, useDispatch } from 'react-redux'
import {
  getDadosAuditoriaSelecionada,
  getParecerIndicadorGestaoSelecionado,
  getListaParecerIndicadorGestao,
  getListaPrincipiosEmpresa,
  getIsSavingParecerIndicadorGestao,
} from 'store/modules/auditor/auditor.selectors'
import { callSalvarParecerIndicador } from 'store/modules/auditor'
import {
  comboIndicadoresTipoComentario,
  comboIndicadoresComentarSobre,
  comboIndicadoresPreenchimentoIndicadorEspecifico,
} from 'utils/constantes'
import { apiListarAuditoresEmpresa, apiSalvarEditarAuditorIndicadores, apiSalvarEditarAuditorPressao } from 'store/modules/api/auditor.actions'

const useStyles = makeStyles((theme) => ({
  withoutResults: {
    fontSize: '32px',
    fontWeight: '400',
    color: '#C9CEDB',
    padding: '30px 0',
    textAlign: 'center',
  },
  contentBox: {
    minHeight: '50vh',
  },
  maxInput: {
    maxWidth: '420px',
  },
  footerSection: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  search: {
    marginBottom: '32px',
  },
  listComments: {
    listStyle: 'none',
    padding: '32px 10px',
    borderTop: `1px solid ${theme.palette.grey.main}`,
    display: 'flex',
    flexDirection: 'column',
    rowGap: '12px',
  },
  lineComment: {
    display: 'flex',
    columnGap: '16px',
  },
  textComment: {
    fontSize: '14px',
    color: '#0F3444',
    whiteSpace: 'pre-line',
    margin: 0,
  },
  firstColumn: {
    minWidth: '180px',
  },
  pending: {
    displa: 'block',
    borderRadius: '100px',
    padding: '3px 12px',
    color: '#FFFFFF',
    backgroundColor: '#F85453',
  },
  concluded: {
    displa: 'block',
    borderRadius: '100px',
    padding: '3px 12px',
    color: '#FFFFFF',
    backgroundColor: '#219754',
  },
  pagination: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.grey.main}`,
  },
  modalContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    boxShadow: theme.shadows[5],
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    minWidth: 400,
  },
}))

const TabDesempenhoBiodiversidade = forwardRef(({
  controlAdded,
  setControlAdded,
}, ref) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()

  const dadosAuditoria = useSelector(getDadosAuditoriaSelecionada)
  const parecerSelecionado = useSelector(getParecerIndicadorGestaoSelecionado)
  const listaPareceres = useSelector(getListaParecerIndicadorGestao)
  const principios = useSelector(getListaPrincipiosEmpresa)
  const isSaving = useSelector(getIsSavingParecerIndicadorGestao)

  const [auditorValue, setAuditorValue] = useState('')
  const [parecerId, setParecerId] = useState(null)
  const [auditorId, setAuditorId] = useState(null)
  const [auditorList, setAuditorList] = useState([])
  const [editAuditor, setEditAuditor] = useState(false)

  const [page, setPage] = useState(0)
  const pageSize = 5
  const filterScreen = [
    { value: true, label: 'filtros.maisAntigos', name: 'time' },
    { value: false, label: 'filtros.maisRecentes', name: 'time' },
  ]

  const handleSave = (values) => {
    const data = {
      ...values,
      auditoriaEmpresaId: dadosAuditoria.id,
    }

    dispatch(callSalvarParecerIndicador(data))

    setTimeout(() => {
      setControlAdded(false)
    }, 800)
  }

  const handleEditAuditor = (parecer) => {
    setAuditorValue(parecer.auditor)
    setParecerId(parecer.id)
    setAuditorId(parecer.usuarioId)
    setEditAuditor(true)
  }

  useEffect(() => {
    const fetchAuditores = async () => {
      if (dadosAuditoria && dadosAuditoria.empresaId) {
        const result = await dispatch(apiListarAuditoresEmpresa({
          empresaId: dadosAuditoria.empresaId,
        }))
        setAuditorList(result.payload)
      }
    }

    fetchAuditores()
  }, [dispatch, dadosAuditoria])

  const handleSaveAuditor = () => {
    const body = {
      auditorId,
      parecerId,
      nome: auditorValue,
    }

    dispatch(apiSalvarEditarAuditorIndicadores(body))

    setEditAuditor(false)
  }

  useImperativeHandle(
    ref,
    () => ({
      handleButtonAdded() {
        setControlAdded(true)
      },
    }),
  )

  const [data, setData] = useState(listaPareceres)

  useEffect(() => {
    setData(listaPareceres)
  }, [listaPareceres])

  const filteredData = data
    .slice(page * pageSize, page * pageSize + pageSize).map((item) => ({ ...item }))

  const dinamicValidation = yup.object({
    auditor: yup.string().required(),
    tipoComentario: yup.string().required(),
    tipoOcorrencia: yup.string().required(),
    validade: yup.string().required(),
    comentario: yup.string().required(),
    principioId: yup.number().when(
      'tipoOcorrencia',
      {
        is: (val) => val === 'PREENCHIMENTO_INDICADOR_ESPECIFICO',
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
      },
    ),
    criterioId: yup.number().when(
      'tipoOcorrencia',
      {
        is: (val) => val === 'PREENCHIMENTO_INDICADOR_ESPECIFICO',
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
      },
    ),
    indicadorId: yup.number().when(
      'tipoOcorrencia',
      {
        is: (val) => val === 'PREENCHIMENTO_INDICADOR_ESPECIFICO',
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
      },
    ),
    campo: yup.string().when(
      'tipoOcorrencia',
      {
        is: (val) => val === 'PREENCHIMENTO_INDICADOR_ESPECIFICO',
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
      },
    ),
  })

  const customClean = (form) => {
    form.change('qualPrincipio', '')
    form.change('qualCriterio', '')
    form.change('qualIndicador', '')
    form.change('campoItem', '')
  }

  return (
    <div className={classes.contentBox}>
      <div className={classes.search}>
        <Filters
          fullWidth
          items={listaPareceres}
          setItems={setData}
          origin={{ vertical: 'top', horizontal: 'right' }}
          filterScreen={filterScreen}
          placeholder={`${data.length} ${t('inputs.comentarios')}`}
          disabled
        />
      </div>

      {controlAdded && (
        <Form
          onSubmit={handleSave}
          validate={validateFormValues(dinamicValidation)}
          initialValues={{
            auditor: parecerSelecionado?.auditor ?? '',
            tipoComentario: parecerSelecionado?.tipoComentario ?? '',
            tipoOcorrencia: parecerSelecionado?.tipoOcorrencia ?? '',
            validade: parecerSelecionado?.validade ?? '',
            comentario: parecerSelecionado?.comentario ?? '',
            principioId: parecerSelecionado?.principioId ?? null,
            criterioId: parecerSelecionado?.criterioId ?? null,
            indicadorId: parecerSelecionado?.indicadorId ?? null,
            campo: parecerSelecionado?.campo ?? null,
          }}
          render={({ handleSubmit, values, form, pristine, valid }) => (
            <form onSubmit={handleSubmit}>
              <MainColumn>
                <FieldText
                  required
                  name="auditor"
                  label={t('inputs.auditorResponsavel')}
                  className={classes.maxInput}
                />

                <FieldSelect
                  required
                  id="tipoComentario"
                  name="tipoComentario"
                  label={t('inputs.tipoComentario')}
                  options={(comboIndicadoresTipoComentario || []).map((option) => ({
                    label: t(option.label),
                    value: option.id,
                  }))}
                  className={classes.maxInput}
                />

                <FieldSelect
                  required
                  id="tipoOcorrencia"
                  name="tipoOcorrencia"
                  label={t('inputs.comentarSobre')}
                  options={(comboIndicadoresComentarSobre || []).map((option) => ({
                    label: t(option.label),
                    value: option.id,
                  }))}
                  className={classes.maxInput}
                  onBlur={() => customClean(form)}
                />

                {values.tipoOcorrencia === 'PREENCHIMENTO_INDICADOR_ESPECIFICO' && (
                  <>
                    <FieldSelect
                      required
                      id="principioId"
                      name="principioId"
                      label={t('inputs.qualPrincipio')}
                      options={(principios || []).map((option) => ({
                        label: t('auditoriaEmpresa.principioNumero', { numero: option.numero }),
                        value: option.id,
                      }))}
                      className={classes.maxInput}
                    />
                    <FieldSelect
                      required
                      id="criterioId"
                      name="criterioId"
                      label={t('inputs.qualCriterio')}
                      options={
                        find(principios, { id: values.principioId })?.criterios?.map((option) => ({
                          label: t('auditoriaEmpresa.criterioNumero', { numero: option.numero }),
                          value: option.id,
                        }))
                      }
                      className={classes.maxInput}
                    />
                    <FieldSelect
                      required
                      id="indicadorId"
                      name="indicadorId"
                      label={t('inputs.qualIndicador')}
                      options={
                        find(
                          find(principios, { id: values.principioId })?.criterios,
                          { id: values.criterioId },
                        )?.indicadores?.map((indicador) => ({
                          label: t('auditoriaEmpresa.indicadorNumero', { numero: indicador.numero }),
                          value: indicador.id,
                        }))
                      }
                      className={classes.maxInput}
                    />
                    <FieldSelect
                      required
                      id="campo"
                      name="campo"
                      label={t('inputs.campoItem')}
                      options={comboIndicadoresPreenchimentoIndicadorEspecifico.map((option) => ({
                        label: t(option.label),
                        value: option.id,
                      }))}
                      className={classes.maxInput}
                    />
                  </>
                )}

                <FieldText
                  required
                  name="validade"
                  label={t('inputs.validadeEmDias')}
                  type="number"
                  className={classes.maxInput}
                />
                <FieldText
                  required
                  name="comentario"
                  label={t('inputs.comentarioAuditoria')}
                  multiline
                  minRows={5}
                />
              </MainColumn>
              <div className={classes.footerSection}>
                <Button
                  variant="danger"
                  onClick={() => setControlAdded(false)}
                >
                  {t('inputs.cancelar')}
                </Button>

                <Button
                  disabled={pristine || !valid}
                  loading={isSaving}
                  type="submit"
                >
                  {t('inputs.salvar')}
                </Button>
              </div>
            </form>
          )}
        />
      )}

      {data.length < 1 ? (
        <Typography component="h1" className={classes.withoutResults}>
          {t('auditoriaEmpresa.voceAindaNaoFezComentarios')}
        </Typography>
      ) : (
        <>
          {filteredData.map((parecer, index) => (
            <ul className={classes.listComments} key={index}>
              <li className={classes.lineComment}>
                <h3 className={clsx(classes.textComment, classes.firstColumn)}>{t('auditoriaEmpresa.auditorResponsavel')}</h3>
                <p className={classes.textComment}>{parecer.auditor}</p>
                <Button xSmall onClick={() => handleEditAuditor(parecer)}>
                  {t('editar')}
                </Button>
              </li>
              <li className={classes.lineComment}>
                <h3 className={clsx(classes.textComment, classes.firstColumn)}>{t('auditoriaEmpresa.tipoComentario')}</h3>
                <p className={classes.textComment}>
                  {t(find(comboIndicadoresTipoComentario, { id: parecer.tipoComentario })?.label)}
                </p>
              </li>
              <li className={classes.lineComment}>
                <h3 className={clsx(classes.textComment, classes.firstColumn)}>{t('auditoriaEmpresa.comentarioSobre')}</h3>
                <p className={classes.textComment}>
                  {t(find(comboIndicadoresComentarSobre, { id: parecer.tipoOcorrencia })?.label)}
                </p>
              </li>
              {parecer.tipoOcorrencia === 'PREENCHIMENTO_INDICADOR_ESPECIFICO' && (
                <>
                  <li className={classes.lineComment}>
                    <h3 className={clsx(classes.textComment, classes.firstColumn)}>{t('auditoriaEmpresa.qualPrincipio')}</h3>
                    <p className={classes.textComment}>{t('auditoriaEmpresa.principioNumero', { numero: parecer.principio?.numero })}</p>
                  </li>
                  <li className={classes.lineComment}>
                    <h3 className={clsx(classes.textComment, classes.firstColumn)}>{t('auditoriaEmpresa.qualCriterio')}</h3>
                    <p className={classes.textComment}>{t('auditoriaEmpresa.criterioNumero', { numero: parecer.criterio?.numero })}</p>
                  </li>
                  <li className={classes.lineComment}>
                    <h3 className={clsx(classes.textComment, classes.firstColumn)}>{t('auditoriaEmpresa.qualIndicador')}</h3>
                    <p className={classes.textComment}>{t('auditoriaEmpresa.indicadorNumero', { numero: parecer.indicador?.numero })}</p>
                  </li>
                  <li className={classes.lineComment}>
                    <h3 className={clsx(classes.textComment, classes.firstColumn)}>{t('auditoriaEmpresa.campoItem')}</h3>
                    <p className={classes.textComment}>
                      {t(find(comboIndicadoresPreenchimentoIndicadorEspecifico, {
                        id: parecer.campo,
                      }).label)}
                    </p>
                  </li>
                </>
              )}
              <li className={classes.lineComment}>
                <h3 className={clsx(classes.textComment, classes.firstColumn)}>{t('auditoriaEmpresa.validadeDias')}</h3>
                <p className={classes.textComment}>{parecer.validade}</p>
              </li>
              <li className={classes.lineComment}>
                <h3 className={clsx(classes.textComment, classes.firstColumn)}>{t('auditoriaEmpresa.comentario')}</h3>
                <p className={classes.textComment}>{parecer.comentario}</p>
              </li>
              <li className={classes.lineComment}>
                <h3 className={clsx(classes.textComment, classes.firstColumn)}>{t('auditoriaEmpresa.status')}</h3>
                <p className={
                  clsx(
                    classes.textComment,
                    parecer.resolvido ? classes.concluded : classes.pending,
                  )
                }
                >{t(`auditoriaEmpresa.${(parecer.resolvido ? 'concluido' : 'pendente')}`)}
                </p>
              </li>
            </ul>
          ))}
          <div className={classes.pagination}>
            {!!filteredData.length && (
              <Pagination
                page={page}
                onChange={setPage}
                pageSize={pageSize}
                count={data.length}
              />
            )}
          </div>
        </>
      )}
      <Modal open={editAuditor} onClose={() => setEditAuditor(false)}>
        <div className={classes.modalContent}>
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <Typography style={{
              color: 'rgba(0, 0, 0, 0.54)',
              fontSize: '0.8rem'
            }}
            >
              {t('inputs.EnviarEmailsPara')}
            </Typography>
            <Select
              label={t('inputs.auditorResponsavelId')}
              value={auditorId}
              onChange={(e) => {
                setAuditorId(e.target.value)
              }}
            >
              {auditorList.length > 0 && auditorList?.map((auditor) => (
                <MenuItem key={auditor.id} value={auditor.id}>
                  {auditor.nome}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <TextField
            label={t('inputs.auditorResponsavel')}
            value={auditorValue}
            onChange={(e) => setAuditorValue(e.target.value)}
          />
          <Button onClick={handleSaveAuditor}>{t('inputs.salvar')}</Button>
        </div>
      </Modal>

    </div>
  )
})

TabDesempenhoBiodiversidade.propTypes = {
  controlAdded: PropTypes.bool,
  setControlAdded: PropTypes.func,
}

export default TabDesempenhoBiodiversidade
