import React from 'react'
import { Form } from 'react-final-form'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { FieldSelect } from 'components/Fields'
import * as yup from 'yup'
import { Grid, makeStyles } from '@material-ui/core'
import BuildingIcon from 'remixicon-react/BuildingLineIcon'
import BankIcon from 'remixicon-react/BankCardLineIcon'
import FundsIcon from 'remixicon-react/FundsLineIcon'
import LeafIcon from 'remixicon-react/LeafLineIcon'
import Button from 'components/Button'
import { getIdiomas } from 'store/modules/idiomas/idiomas.selectors'
import { callRelatorioGestaoResumido } from 'store/modules/relatorios/gestao'
import { callRelatorioIndicadoresGestaoResumido } from 'store/modules/relatorios/indicadoresGestao'
import { callRelatorioIPBResumido } from 'store/modules/relatorios/IPB'
import { callRelatorioPABSResumido } from 'store/modules/relatorios/PABS'
import { getIsCarregandoRelatorioGestaoResumido } from 'store/modules/relatorios/gestao/relatoriosGestao.selectors'
import { getIsCarregandoRelatorioIndicadoresGestaoResumido } from 'store/modules/relatorios/indicadoresGestao/relatoriosIndicadoresGestao.selectors'
import { getIsCarregandoRelatorioIPBResumido } from 'store/modules/relatorios/IPB/relatoriosIPB.selectors'
import { getIsCarregandoRelatorioPABSResumido } from 'store/modules/relatorios/PABS/relatoriosPABS.selectors'
import { getEmpresaUsuario } from 'store/modules/auth/auth.selectors'
import moment from 'moment'
import { validateFormValues } from 'utils/forms'
import { map, range } from 'lodash'
import Card from './Card'

const useStyles = makeStyles((theme) => ({
  submitButton: {
    marginTop: theme.spacing(3),
  },
}))

const RelatoriosResumidos = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()

  const idiomas = useSelector(getIdiomas)
  const loadingGestaoResumido = useSelector(getIsCarregandoRelatorioGestaoResumido)
  const loadingIndicadoresGestaoResumido =
    useSelector(getIsCarregandoRelatorioIndicadoresGestaoResumido)
  const loadingIPBGestaoResumido = useSelector(getIsCarregandoRelatorioIPBResumido)
  const loadingPABSGestaoResumido = useSelector(getIsCarregandoRelatorioPABSResumido)

  const empresa = useSelector(getEmpresaUsuario)

  const RELATORIOS = [
    {
      sigla: t('relatorios.siglaGeral'),
      title: t('relatorios.relatorioGeral'),
      icon: BuildingIcon,
      action: callRelatorioGestaoResumido,
      loading: loadingGestaoResumido,
    },
    { sigla: t('relatorios.siglaIndicePressaoBiodiversidade'),
      title: t('relatorios.pressaoBiodiversidade'),
      icon: FundsIcon,
      action: callRelatorioIPBResumido,
      loading: loadingIPBGestaoResumido },
    {
      sigla: t('relatorios.siglaDesempenhoBiodiversidade'),
      title: t('relatorios.desempenhoBiodiversidade'),
      icon: LeafIcon,
      action: callRelatorioPABSResumido,
      loading: loadingPABSGestaoResumido,
    },
    {
      sigla: t('relatorios.siglaIndicadoresGestao'),
      title: t('relatorios.indicadoresGestao'),
      icon: BankIcon,
      action: callRelatorioIndicadoresGestaoResumido,
      loading: loadingIndicadoresGestaoResumido,
    },
  ]

  const anoAtual = +moment().format('YYYY')
  const anos = map(range(2014, anoAtual + 2), (ano) => ({
    value: ano.toString(), label: `${(ano - 1).toString()}/${ano.toString().slice(2)}`,
  }))

  return (
    <Grid spacing={4} container>
      {RELATORIOS.map(({ sigla, action, title, icon, loading }) => (
        <Grid item xs={12} md={4} key={sigla}>
          <Form
            validate={validateFormValues(yup.object({
              anoBase: yup.string().required(),
              idioma: yup.number().required(),
            }))}
            onSubmit={(values) => dispatch(action({
              ...values,
              empresaId: empresa.id,
            }))}
            render={({ handleSubmit, submitting, values }) => (
              <form onSubmit={handleSubmit}>
                <Card color="green" title={title} icon={icon}>
                  <Grid spacing={1} justifyContent="center" container>
                    <Grid item xs={6}>
                      <FieldSelect
                        required
                        name="anoBase"
                        label={t('inputs.anoBase')}
                        options={anos}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FieldSelect
                        required
                        name="idioma"
                        label={t('inputs.idioma')}
                        options={idiomas.map((idioma) => ({
                          label: t(idioma.label),
                          value: idioma.id,
                        }))}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        loading={loading}
                        loadingText="inputs.gerandoRelatorio"
                        type="submit"
                        className={classes.submitButton}
                        disabled={!values.anoBase || !values.idioma || submitting}
                      >
                        {t('relatorios.downloadRelatorio')}
                      </Button>
                    </Grid>
                  </Grid>
                </Card>
              </form>
            )}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default RelatoriosResumidos
