import React, { useEffect, useState, useCallback, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ListItemText,
  Typography,
  makeStyles,
} from '@material-ui/core'
import ArrowDownIcon from 'remixicon-react/ArrowDownSLineIcon'
import { useTranslation } from 'react-i18next'
import { find } from 'lodash'
import { getPaises, getPaisSelecionado } from 'store/modules/paises/paises.selectors'
import { callListarDadosPaisCalculos, callSelecionarPais } from 'store/modules/paisesCalculos'
import { callNovoPais } from 'store/modules/paises'
import PageTitle from 'components/PageTitle'
import Select from 'components/Fields/BaseSelect'
import Button from 'components/Button'
import ColumnsBox from 'components/ColumnsBox'
import { getTipoUsuarioLogado } from 'store/modules/auth/auth.selectors'
import { TIPO_USUARIO } from 'utils/constantes'
import { ListItemStyled } from './styles'
import Grupos from './PABS/Grupos'
import Temas from './PABS/Temas'
import Cadastros from './PABS/Cadastros'
import Qualificadores from './PABS/Qualificadores'
import PaisRegiao from './PaisRegiao'
import Gee from './IPB/Gee'
import FontesEnergia from './IPB/FontesEnergia'
import Silvicultura from './Silvicultura'
import FatoresValores from './FatoresValores'
import SetorEmpresa from './SetorEmpresa'
import Agua from './Agua'
import Principios from './Indicadores/Principios'
import MatrizResiduos from './IPB/MatrizResiduos'
import Criterios from './Indicadores/Criterios'
import Area from './Area'
import ClassesIndicadores from './ClassesIndicadores'
import Indicadores from './Indicadores/Indicadores'

const useStyles = makeStyles((theme) => ({
  contentBox: {
    padding: theme.spacing(4, 5),
  },
  headerSelect: {
    maxWidth: 216,
  },
  headerActions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  headerButton: {
    marginLeft: theme.spacing(3),
  },
  emptyText: {
    fontSize: 14,
    fontStyle: 'italic',
    padding: theme.spacing(1, 0),
    color: theme.palette.grey.A0,
    fontWeight: theme.typography.fontWeightLight,
  },
  list: {
    margin: '0 !important',
    borderBottom: `1px solid ${theme.palette.grey.main}`,
    '&:before': {
      display: 'none',
    },
    '&:first-child': {
      marginTop: '-12px !important',
    },
    '&:last-child': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    '&.Mui-disabled': {
      backgroundColor: 'transparent',
    },
  },
  listItem: {
    fontSize: 20,
    minHeight: 'auto !important',
    padding: theme.spacing(2, 1),
    color: theme.palette.primary.main,
    '&.Mui-disabled': {
      opacity: 0.25,
      color: theme.palette.common.black,
    },
  },
  listItemText: {
    margin: '0 !important',
  },
  subItems: {
    padding: 0,
    display: 'block',
  },
  subItem: {
    fontSize: 14,
    borderRadius: 4,
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  subItemActive: {
    backgroundColor: theme.palette.grey.F5,
  },
  subItemText: {
    margin: 0,
  },
  screenTitle: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.pxToRem(24),
    borderBottom: `1px solid ${theme.palette.grey.main}`,
  },
  screenBtnTitle: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(5),
    fontSize: theme.typography.pxToRem(24),
  },
  sectionTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey.main}`,
  },
}))

const PaisesCalculos = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()

  const listaPaises = useSelector(getPaises)
  const paisReducer = useSelector(getPaisSelecionado)
  const tipoUsuarioLogado = useSelector(getTipoUsuarioLogado)

  const [addPaisRegiao, setAddPaisRegiao] = useState(false)
  const [menuSelecionado, setMenuSelecionado] = useState({})
  const [paisSelecionado, setPaisSelecionado] = useState('')

  const menuItens = [
    {
      nome: t('paisesCalculos.ipb'),
      subMenus: [
        {
          nome: t('paisesCalculos.residuos'),
          tela: MatrizResiduos,
        },
        {
          nome: t('paisesCalculos.Gee'),
          tela: Gee,
        },
        {
          nome: t('paisesCalculos.Energia'),
          tela: FontesEnergia,
        },
        {
          nome: t('paisesCalculos.Agua'),
          tela: Agua,
        },
        {
          nome: t('paisesCalculos.Silvicultura'),
          tela: Silvicultura,
        },
        {
          nome: t('paisesCalculos.Area'),
          tela: Area,
        },
        {
          nome: t('paisesCalculos.FatoresValores'),
          tela: FatoresValores,
        },
        {
          nome: t('paisesCalculos.SetorEmpresa'),
          tela: SetorEmpresa,
        },
      ],
    },
    {
      nome: t('paisesCalculos.pabs'),
      subMenus: [
        { nome: t('paisesCalculos.grupos'), tela: Grupos },
        { nome: t('paisesCalculos.temas'), tela: Temas },
        { nome: t('paisesCalculos.cadastros'), tela: Cadastros },
        { nome: t('paisesCalculos.qualificadores'), tela: Qualificadores },
      ],
    },
    {
      nome: t('paisesCalculos.irc'),
      subMenus: [
        {
          nome: t('paisesCalculos.ClassesIndicadores'), tela: ClassesIndicadores,
        },
      ],
    },
    {
      nome: t('paisesCalculos.indicadores'),
      subMenus: [
        { nome: t('paisesCalculos.principios'), tela: Principios },
        { nome: t('paisesCalculos.criterios'), tela: Criterios },
        { nome: t('paisesCalculos.indicadores'), tela: Indicadores },
      ],
    },
    {
      nome: t('paisesCalculos.informacoesGerais'),
      subMenus: [
        { nome: t('paisesCalculos.editarPaisRegiao'), tela: PaisRegiao },
      ],
    },
  ]

  const handleListChange = (menu, subMenu) => () => {
    setMenuSelecionado({ menu, subMenu })
    setAddPaisRegiao(false)
  }

  const handleCountryChange = useCallback(({ target }) => {
    setMenuSelecionado({})
    setPaisSelecionado(target.value)
    dispatch(callListarDadosPaisCalculos(target.value))
    dispatch(callSelecionarPais(find(listaPaises, { id: target.value })))
  }, [dispatch, listaPaises])

  const handleSubmenuCancel = () => {
    setMenuSelecionado(({ menu }) => ({ menu, subMenu: null }))
  }

  const [accordionStatus, setAccordionStatus] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
  })

  const handleChangeAccordion = (n) => {
    setAccordionStatus({ ...accordionStatus, [n]: !accordionStatus[n] })
  }

  const handleAddCountry = () => {
    setAccordionStatus({
      0: false,
      1: false,
      2: false,
      3: false,
      4: false,
    })
    setAddPaisRegiao(true)
    setMenuSelecionado({})
    setPaisSelecionado('')
    dispatch(callNovoPais())
  }

  const { tela: CurrentScreen } = (() => {
    const { subMenus = [] } = menuItens[menuSelecionado.menu] || {}
    return subMenus[menuSelecionado.subMenu] || {}
  })()

  useEffect(() => {
    const deleted = !listaPaises.some((pais) => pais.id === paisSelecionado)
    if (deleted) {
      setPaisSelecionado('')
      setMenuSelecionado({})
    }
  }, [listaPaises, paisSelecionado])

  useEffect(() => {
    if (paisReducer && paisReducer.id !== paisSelecionado) {
      handleCountryChange({ target: { value: paisReducer?.id } })
    }
  }, [handleCountryChange, paisReducer, paisSelecionado])

  return (
    <>
      <PageTitle title={t('menuItemsAdm.paisesCalculos')}>
        <div className={classes.headerActions}>
          <Select
            theme="light"
            value={paisSelecionado}
            onChange={handleCountryChange}
            className={classes.headerSelect}
            label={t('inputs.selecionePaisRegiao')}
            options={listaPaises.map((pais) => ({ label: pais.nome, value: pais.id }))}
          />
          {tipoUsuarioLogado === TIPO_USUARIO.ADMIN.ADMIN && (
            <Button onClick={handleAddCountry} className={classes.headerButton}>
              {t('inputs.adicionarPaisRegiao')}
            </Button>
          )}
        </div>
      </PageTitle>
      <ColumnsBox
        className={classes.contentBox}
        leftColumn={menuItens.map((menuItem, index) => (
          <Fragment key={menuItem.nome}>
            <Accordion
              elevation={0}
              className={classes.list}
              disabled={!paisSelecionado || menuItem?.disabled}
              expanded={accordionStatus[index]}
              onChange={() => handleChangeAccordion(index)}
            >
              <AccordionSummary
                expandIcon={<ArrowDownIcon />}
                IconButtonProps={{ size: 'small' }}
                classes={{ root: classes.listItem, content: classes.listItemText }}
              >
                {menuItem.nome}
              </AccordionSummary>
              {!!menuItem.subMenus.length && (
                <AccordionDetails className={classes.subItems}>
                  {menuItem.subMenus.map((subMenu, subIndex) => (
                    <ListItemStyled
                      disabled={subMenu.disabled}
                      button
                      disableRipple
                      key={subMenu.nome}
                      onClick={handleListChange(index, subIndex)}
                      classes={{ root: classes.subItem, selected: classes.subItemActive }}
                      selected={
                        menuSelecionado.menu === index && menuSelecionado.subMenu === subIndex
                      }
                    >
                      <ListItemText
                        title={subMenu.nome}
                        primary={subMenu.nome}
                        className={classes.subItemText}
                      />
                    </ListItemStyled>
                  ))}
                </AccordionDetails>
              )}
            </Accordion>
          </Fragment>
        ))}
        rightColumn={(paisSelecionado || addPaisRegiao) ? (
          <div>
            {addPaisRegiao && (
              <PaisRegiao
                onCancel={handleSubmenuCancel}
                onCreate={handleListChange(menuItens.length - 1, 0)}
              />
            )}
            {CurrentScreen && (
              <CurrentScreen paisId={paisSelecionado} onCancel={handleSubmenuCancel} />
            )}
          </div>
        ) : (
          <Typography className={classes.emptyText}>
            {t('paisesCalculos.selecionePaisRegiao')}
          </Typography>
        )}
      />
    </>
  )
}

export default PaisesCalculos
