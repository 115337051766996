import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput as MuiOutlinedInput,
  Select,
  Typography,
  makeStyles,
  withStyles,
} from '@material-ui/core'
import clsx from 'clsx'
import ComponentCard from 'components/Card'
import MainColumn from 'components/MainColumn'
import PageTitle from 'components/PageTitle'
import { map, range } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import DeleteBin7FillIcon from 'remixicon-react/DeleteBin7LineIcon'
import DropLineIcon from 'remixicon-react/DropLineIcon'
import FolderChart2LineIcon from 'remixicon-react/FolderChart2LineIcon'
import FolderShieldLineIcon from 'remixicon-react/FolderShieldLineIcon'
import LightbulbLineIcon from 'remixicon-react/LightbulbLineIcon'
import TreasureMapLineIcon from 'remixicon-react/TreasureMapLineIcon'
import WindyLineIcon from 'remixicon-react/WindyLineIcon'
import { getEmpresaUsuario } from 'store/modules/auth/auth.selectors'
import { callListarDadosDashboard } from 'store/modules/dashboard'
import { getAnoCarregado, getDadosDashboard, getUnidadeCarregada } from 'store/modules/dashboard/dashboard.selectors'
import { getUnidades } from 'store/modules/unidades/unidades.selectors'
import { formatDecimal } from 'utils/numbers'
import Card from './Card'
import CardInfo from './CardInfo'
import CardStatistics from './CardStatistics'

const OutlinedInput = withStyles((theme) => ({
  root: {
    '& $notchedOutline': {
      borderColor: theme.palette.white.main,
    },
    '&:hover $notchedOutline': {
      borderColor: theme.palette.white.main,
    },
    '&$focused $notchedOutline': {
      borderColor: theme.palette.white.main,
    },
  },
  focused: {},
  notchedOutline: {},
}))(MuiOutlinedInput)

const useStyles = makeStyles((theme) => ({
  select: {
    color: theme.palette.white.main,
  },
  iconSelect: {
    color: theme.palette.white.main,
  },
  formControl: {
    height: 60,
    width: 260,
    marginLeft: theme.spacing(1),
    '& .MuiFormLabel-root': {
      color: theme.palette.white.main,
      fontSize: theme.typography.pxToRem(14),
    },
    '& .MuiInputBase-root:hover': {
      color: theme.palette.white.main,
    },
  },
  selectDiv: {
    color: '#0F3444',
  },
  iconSelectDiv: {
    color: '#0F3444',
  },
  formControlDiv: {
    height: 60,
    width: 216,
    border: '1px solid #C9CEDB',
    borderRadius: '4px',
    '& .MuiFormLabel-root': {
      color: '#0F3444',
      fontSize: theme.typography.pxToRem(14),
      backgroundColor: '#FFFFFF',
      paddingLeft: '5px',
      paddingRight: '20px',
    },
    '& .MuiInputBase-root:hover': {
      color: '#0F3444',
    },
  },
  containerMain: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '24px',
    '& ul': {
      listStyle: 'none',
    },
  },
  container: {
    padding: theme.spacing(3),
    boxShadow: '0px 8px 32px rgba(15, 52, 68, 0.08)',
    borderRadius: '8px',
    backgroundColor: theme.palette.common.white,
  },
  minCard: {
    padding: '16px',
    borderRadius: '8px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 8px 32px rgba(15, 52, 68, 0.08)',
    margin: '16px 0',
  },
  minCardContainerTitle: {
    display: 'flex',
    columnGap: '10px',
    alignItems: 'center',
  },
  boxTitle: {
    fontSize: 24,
    fontFamily: theme.typography.secondary,
  },
  cardTitle: {
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    fontFamily: theme.typography.secondary,
    marginTop: theme.spacing(1),
  },
  simpleText: {
    fontSize: 10,
    color: theme.palette.primary.main,
    fontFamily: theme.typography.secondary,
  },
  italicText: {
    marginTop: '32px',
    fontSize: 12,
    color: theme.palette.primary.main,
    fontFamily: theme.typography.secondary,
    fontStyle: 'italic',
  },
  boxFullBlue: {
    marginTop: '16px',
    background: 'linear-gradient(180deg, #C5E8F7 0%, #FFFFFF 25%)',
    boxShadow: '0px 8px 32px 0px #0F344414',
    borderRadius: '8px',
    padding: '16px',
    width: '100%',
  },
  containerTitle: {
    display: 'flex',
    columnGap: '22px',
    alignItems: 'center',
    marginBottom: '16px',
  },
  buttonModelLink: {
    background: 'transparent',
    border: 'none',
    color: '#2A95C3',
    textDecoration: 'underline',
    lineHeight: '16px',
    fontSize: '14px',
    cursor: 'pointer',
  },
  selectDivision: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px',
    '& div:first-child': {
      marginBottom: 0,
    },
  },
  structureTable: {
    display: 'flex',
    justifyContent: 'space-between',
    '& li': {
      fontSize: '14px',
      '&:nth-child(1)': {
        width: '52%',
      },
      '&:nth-child(2)': {
        width: '16.36%',
      },
      '&:nth-child(3)': {
        width: '16.36%',
      },
      '&:nth-child(4)': {
        width: '6.54%',
      },
    },
  },
  titleTable: {
    color: '#78839D',
    marginTop: '32px',
    padding: 0,
  },
  bodyTable: {
    color: '#0F3444',
    borderBottom: '2px solid #C9CEDB',
    padding: '0 0 8px 0',
    margin: '0 0 8px 0',
    '&:last-child': {
      borderBottom: 'none',
    },
    '& p': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  red: {
    '& h2': {
      color: '#F85453',
    },
    '& svg': {
      fill: '#F85453',
    },
  },
  green: {
    '& h2': {
      color: '#219754',
    },
    '& svg': {
      fill: '#219754',
    },
  },
  blue: {
    '& h2': {
      color: '#31ADE3',
    },
    '& svg': {
      fill: '#31ADE3',
    },
  },
  yellow: {
    '& h2': {
      color: '#FDC02E',
    },
    '& svg': {
      fill: '#FDC02E',
    },
  },
  orange: {
    '& h2': {
      color: '#EE7D40',
    },
    '& svg': {
      fill: '#EE7D40',
    },
  },
  minCardDescription: {
    fontSize: 32,
    display: 'flex',
    alignItems: 'flex-end',
    letterSpacing: '-0.02em',
    marginTop: theme.spacing(1),
    minHeight: theme.spacing(5),
  },
}))

const anoAtual = +moment().format('YYYY')
const anos = map(range(2014, anoAtual + 2), (ano) => ({
  value: ano.toString(), label: `${(ano - 1).toString()}/${ano.toString().slice(2)}`,
}))

const DashboardPage = () => {
  const { t, i18n } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()

  const empresa = useSelector(getEmpresaUsuario)
  const listaUnidades = useSelector(getUnidades)
  const dashboard = useSelector(getDadosDashboard)
  const anoCarregado = useSelector(getAnoCarregado)
  const unidadeCarregada = useSelector(getUnidadeCarregada)

  const [anoSelecionado, setAnoSelecionado] = useState(anoCarregado || anoAtual.toString())
  const [unidadeSelecionada, setUnidadeSelecionada] = useState(unidadeCarregada || null)

  const handleChangeComboUnidade = (event) => {
    const { value } = event.target
    setUnidadeSelecionada(value)
  }
  const listaUnidadesOrdered = listaUnidades?.sort((a, b) => (
    a?.divisaoSetor?.nome?.localeCompare(b?.divisaoSetor?.nome, i18n.language)
  ))

  const gerarNomeSeletor = (option) => {
    if (!option?.empresa?.possuiDivisaoSetor) {
      return option?.empresa?.nome
    }
    if (!option?.divisaoSetor?.possuiUnidade) {
      return option?.divisaoSetor?.nome
    }
    return `${option?.divisaoSetor?.nome} / ${option?.nome}`
  }

  const handleChangeAno = (event) => {
    setAnoSelecionado(event.target.value)
  }

  useEffect(() => {
    if (anoCarregado !== anoSelecionado || unidadeSelecionada !== unidadeCarregada) {
      dispatch(callListarDadosDashboard({
        empresaId: empresa.id,
        ano: anoSelecionado,
        unidadeId: unidadeSelecionada,
      }))
    }
  }, [anoCarregado, anoSelecionado, dispatch, empresa.id, unidadeCarregada, unidadeSelecionada])

  const datasPressao = [
    {
      color: 'red',
      icon: DeleteBin7FillIcon,
      label: t('pressaoBiodiversidade.residuos'),
      selector: 'indiceResiduo',
    },
    {
      color: 'green',
      icon: WindyLineIcon,
      label: t('pressaoBiodiversidade.GEE'),
      selector: 'indiceEmissaoGas',
    },
    {
      color: 'blue',
      icon: DropLineIcon,
      label: t('pressaoBiodiversidade.agua'),
      selector: 'indiceAgua',
    },
    {
      color: 'yellow',
      icon: LightbulbLineIcon,
      label: t('pressaoBiodiversidade.energia'),
      selector: 'indiceEnergia',
    },
    {
      color: 'orange',
      icon: TreasureMapLineIcon,
      label: t('pressaoBiodiversidade.area'),
      selector: 'indiceArea',
    },
  ]

  const MinCard = ({ icon: Icone, label, content, color }) => (
    <div className={clsx(classes.minCard, classes[color])}>
      <div className={classes.minCardContainerTitle}>
        <Icone />
        <Typography variant="h1" className={classes.cardTitle}>
          {label}
        </Typography>
      </div>
      <Typography variant="h2" className={classes.minCardDescription}>
        {formatDecimal({
          min: 2,
          max: 5,
          locale: i18n.language,
          value: content,
        })}
      </Typography>
    </div>
  )

  MinCard.propTypes = {
    icon: PropTypes.object,
    label: PropTypes.string,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
  }

  return (
    <Grid container justifyContent="center">
      <PageTitle title={t('dashboard.pageTitle')}>
        <div>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="ano-base">
              {t('pressaoBiodiversidade.selecioneAno')}
            </InputLabel>
            <Select
              classes={{
                select: classes.select,
                icon: classes.iconSelect,
              }}
              value={anoSelecionado}
              onChange={handleChangeAno}
              label={t('pressaoBiodiversidade.selecioneAno')}
              inputProps={{
                name: 'ano-base',
                id: 'ano-base',
              }}
              input={<OutlinedInput label={t('pressaoBiodiversidade.selecioneAno')} />}
            >
              {map(anos, (option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </PageTitle>
      <div className={classes.containerMain}>
        <Grid
          container
          item
          xs={12}
          className={classes.container}
        >
          <div className={classes.selectDivision}>
            <div className={classes.containerTitle}>
              <Typography variant="h1" className={classes.boxTitle}>
                {t('dashboard.pressaoBiodiversidade')}
              </Typography>
              <NavLink
                exact
                to={{
                  pathname: '/pressaoBiodiversidade',
                  state: { data: { unidade: unidadeSelecionada, ano: anoSelecionado } },
                }}
                className={classes.buttonModelLink}
              >
                {t('inputs.verTudo')}
              </NavLink>
            </div>
            <FormControl variant="outlined" className={classes.formControlDiv}>
              <InputLabel htmlFor="divisao-setor-unidade">
                {t('inputs.divisaoUnidade')}
              </InputLabel>
              <Select
                classes={{
                  select: classes.selectDiv,
                  icon: classes.iconSelectDiv,
                }}
                value={unidadeSelecionada || ''}
                onChange={handleChangeComboUnidade}
                inputProps={{
                  name: 'divisao-setor-unidade',
                  id: 'divisao-setor-unidade',
                }}
                label={t('pressaoBiodiversidade.selecioneAno')}
                input={<OutlinedInput label={t('pressaoBiodiversidade.selecioneAno')} />}
              >
                {map(listaUnidadesOrdered, (option) => (
                  <MenuItem key={`${option.nome} ${option.id}`} value={option.id}>
                    {gerarNomeSeletor(option)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <MainColumn columns="1fr 1fr 1fr">
            <Card
              title={t('pressaoBiodiversidade.IPBUnidade')}
              content={dashboard.indiceUnidade?.iib}
              color="orange"
              decimalMin={0}
              decimalMax={5}
            />
            <Card
              title={t('pressaoBiodiversidade.DMBUnidade')}
              content={dashboard.indiceUnidade?.acb}
              color="green"
              decimalMin={0}
              decimalMax={2}
            />
            <Card
              title={t('pressaoBiodiversidade.DMBOrganizacao')}
              content={dashboard.indiceUnidade && dashboard.dmbOrganizacao}
              color="blue"
              description={t('pressaoBiodiversidade.informEcoRegiao')}
              decimalMin={0}
              decimalMax={2}
            />
          </MainColumn>
          <MainColumn columns="1fr 1fr 1fr 1fr 1fr">
            {datasPressao.map((elem, index) => (
              <MinCard
                key={index}
                icon={elem.icon}
                label={elem.label}
                content={dashboard.indiceUnidade?.[elem.selector]}
                color={elem.color}
              />
            ))}
          </MainColumn>

          <Typography variant="h3" className={classes.simpleText}>
            {t('dashboard.osIndicesIndividuaisSaoExpressos')}
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          className={classes.container}
        >
          <div className={classes.containerTitle}>
            <Typography variant="h1" className={classes.boxTitle}>
              {t('dashboard.desempenhoBiodiversidade')}
            </Typography>
            <NavLink
              exact
              to={{
                pathname: '/desempenhoBiodiversidade',
                state: { data: { ano: anoSelecionado } },
              }}
              className={classes.buttonModelLink}
            >
              {t('inputs.verTudo')}
            </NavLink>
          </div>
          <MainColumn columns="1fr 1fr 120px 120px">
            <Card
              title={t('desempenhoBiodiversidade.dpbOrganizacao')}
              content={dashboard.dpbOrganizacao}
              color="green"
              description={t('desempenhoBiodiversidade.infoDPBOrganizacao')}
              decimalMin={0}
              decimalMax={2}
            />
            <Card
              title={t('desempenhoBiodiversidade.dmbOrganizacao')}
              content={dashboard.dmbOrganizacao}
              color="blue"
              description={t('pressaoBiodiversidade.informEcoRegiao')}
              decimalMin={0}
              decimalMax={2}
            />
            <Card
              title={t('desempenhoBiodiversidade.projetosCadastrados')}
              content={dashboard.quantidadeProjetos}
              color="purple"
              decimalMin={0}
              decimalMax={0}
            />
            <Card
              title={t('desempenhoBiodiversidade.acoesCadastradas')}
              content={dashboard.quantidadeAcoes}
              color="purple"
              decimalMin={0}
              decimalMax={0}
            />
          </MainColumn>

          <div className={classes.boxFullBlue}>
            <Typography variant="h1" className={classes.cardTitle}>
              {t('dashboard.projetosAcoesVigentesAdicionaisLegislacao')}
            </Typography>
            {dashboard?.projetosVigentesAdicionais?.length > 0 ? (
              <>
                <ul className={clsx(classes.structureTable, classes.titleTable)}>
                  <li>{t('dashboard.nome')}</li>
                  <li>{t('dashboard.pontuacaoSemIRC')}</li>
                  <li>{t('dashboard.pontuacaoComIRC')}</li>
                  <li>{t('dashboard.IRC')}</li>
                </ul>
                {dashboard?.projetosVigentesAdicionais.map((projeto, index) => (
                  <ul className={clsx(classes.structureTable, classes.bodyTable)} key={index}>
                    <li><p>{projeto.nome}</p></li>
                    <li>
                      <p>
                        {formatDecimal({
                          min: 0,
                          max: 2,
                          locale: i18n.language,
                          value: projeto.pontuacaoProjetoAcoesVigentesAdicionais,
                        })}
                      </p>
                    </li>
                    <li>
                      <p>
                        {formatDecimal({
                          min: 0,
                          max: 2,
                          locale: i18n.language,
                          value: projeto.pontuacaoProjetoAcoesVigentesAdicionaisIRC,
                        })}
                      </p>
                    </li>
                    <li>
                      <p>
                        {formatDecimal({
                          min: 0,
                          max: 2,
                          locale: i18n.language,
                          value: projeto.valorIrc,
                        })}
                      </p>
                    </li>
                  </ul>
                ))}

              </>
            ) : (
              <Typography variant="h2" className={classes.italicText}>
                {t('dashboard.aindaNaoForamCadastradosProjetosAcoesNesseAnoBase')}
              </Typography>
            )}
          </div>
        </Grid>

        <Grid
          container
          item
          xs={12}
          className={classes.container}
        >
          <div className={classes.containerTitle}>
            <Typography variant="h1" className={classes.boxTitle}>
              {t('dashboard.indicadoresGestao')}
            </Typography>
            <NavLink
              exact
              to={{
                pathname: '/indicadoresGestao',
                state: { data: { ano: anoSelecionado } },
              }}
              className={classes.buttonModelLink}
            >
              {t('inputs.verTudo')}
            </NavLink>
          </div>
          <MainColumn columns="1fr 1fr">
            <ComponentCard
              hasGradient
              status="purple"
              icon={FolderShieldLineIcon}
              title={t('indicadoresGestao.indicadoresEssenciais')}
            >
              <CardInfo
                atendido={dashboard.dadosIndicadores?.essenciais?.atendido}
                parcialmenteAtendido={dashboard.dadosIndicadores?.essenciais?.parcialmenteAtendido}
                naoAtendido={dashboard.dadosIndicadores?.essenciais?.naoAtendido}
                naoAplicavel={dashboard.dadosIndicadores?.essenciais?.naoAplicavel}
                naoPreenchidos={dashboard.dadosIndicadores?.essenciais?.naoPreenchidos}
              />
            </ComponentCard>
            <ComponentCard
              hasGradient
              status="orange"
              icon={FolderChart2LineIcon}
              title={t('indicadoresGestao.indicadoresGerais')}
            >
              <CardInfo
                atendido={dashboard.dadosIndicadores?.gerais?.atendido}
                parcialmenteAtendido={dashboard.dadosIndicadores?.gerais?.parcialmenteAtendido}
                naoAtendido={dashboard.dadosIndicadores?.gerais?.naoAtendido}
                naoAplicavel={dashboard.dadosIndicadores?.gerais?.naoAplicavel}
                naoPreenchidos={dashboard.dadosIndicadores?.gerais?.naoPreenchidos}
              />
            </ComponentCard>
          </MainColumn>

          <Grid item xs={12}>
            <MainColumn columns="repeat(3, 1fr)">
              <CardStatistics
                percentage={dashboard.dadosIndicadores?.indicadoresAtendidos}
                description={t('indicadoresGestao.estatisticaIndicadoresEssenciaisGerais')}
              />
              <CardStatistics
                percentage={dashboard.dadosIndicadores?.indicadoresEssenciais}
                description={t('indicadoresGestao.estatisticaIndicadoresEssenciais')}
              />
              <CardStatistics
                percentage={dashboard.dadosIndicadores?.indicadoresGerais}
                smallText={t('indicadoresGestao.certificacaoInicial')}
                description={t('indicadoresGestao.estatisticaIndicadoresGerais')}
              />
            </MainColumn>
          </Grid>
        </Grid>
      </div>
    </Grid>
  )
}

export default DashboardPage
