import React from 'react'
import { Form } from 'react-final-form'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { FieldSelect } from 'components/Fields'
import { Grid, makeStyles } from '@material-ui/core'
import * as yup from 'yup'
import { validateFormValues } from 'utils/forms'
import BuildingIcon from 'remixicon-react/BuildingLineIcon'
import BankIcon from 'remixicon-react/BankCardLineIcon'
import FundsIcon from 'remixicon-react/FundsLineIcon'
import LeafIcon from 'remixicon-react/LeafLineIcon'
import Button from 'components/Button'
import { getIdiomas } from 'store/modules/idiomas/idiomas.selectors'
import { callRelatorioGestaoDetalhado } from 'store/modules/relatorios/gestao'
import { callRelatorioIndicadoresGestaoDetalhado } from 'store/modules/relatorios/indicadoresGestao'
import { callRelatorioIPBDetalhado } from 'store/modules/relatorios/IPB'
import { callRelatorioPABSDetalhado } from 'store/modules/relatorios/PABS'
import { getIsCarregandoRelatorioGestaoDetalhado } from 'store/modules/relatorios/gestao/relatoriosGestao.selectors'
import { getIsCarregandoRelatorioIndicadoresGestaoDetalhado } from 'store/modules/relatorios/indicadoresGestao/relatoriosIndicadoresGestao.selectors'
import { getIsCarregandoRelatorioIPBDetalhado } from 'store/modules/relatorios/IPB/relatoriosIPB.selectors'
import { getIsCarregandoRelatorioPABSDetalhado } from 'store/modules/relatorios/PABS/relatoriosPABS.selectors'
import { getEmpresaUsuario } from 'store/modules/auth/auth.selectors'
import moment from 'moment'
import { map, range } from 'lodash'
import { useConfirmationDialog } from 'components/Dialog/ConfirmDialog'
import Card from './Card'

const useStyles = makeStyles((theme) => ({
  submitButton: {
    marginTop: theme.spacing(3),
  },
}))

const RelatoriosDetalhados = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()

  const idiomas = useSelector(getIdiomas)
  const loadingGestaoDetalhado = useSelector(getIsCarregandoRelatorioGestaoDetalhado)
  const loadingIndicadoresGestaoDetalhado =
    useSelector(getIsCarregandoRelatorioIndicadoresGestaoDetalhado)
  const loadingIPBGestaoDetalhado = useSelector(getIsCarregandoRelatorioIPBDetalhado)
  const loadingPABSGestaoDetalhado = useSelector(getIsCarregandoRelatorioPABSDetalhado)

  const empresa = useSelector(getEmpresaUsuario)

  const dialog = useConfirmationDialog()

  const RELATORIOS = [
    {
      sigla: t('relatorios.siglaGeral'),
      title: t('relatorios.relatorioGeral'),
      icon: BuildingIcon,
      action: callRelatorioGestaoDetalhado,
      loading: loadingGestaoDetalhado,
      type: 'mail',
    },
    {
      sigla: t('relatorios.siglaIndicePressaoBiodiversidade'),
      title: t('relatorios.pressaoBiodiversidade'),
      icon: FundsIcon,
      action: callRelatorioIPBDetalhado,
      loading: loadingIPBGestaoDetalhado,
    },
    {
      sigla: t('relatorios.siglaDesempenhoBiodiversidade'),
      title: t('relatorios.desempenhoBiodiversidade'),
      icon: LeafIcon,
      action: callRelatorioPABSDetalhado,
      loading: loadingPABSGestaoDetalhado,
      type: 'mail',
    },
    {
      sigla: t('relatorios.siglaIndicadoresGestao'),
      title: t('relatorios.indicadoresGestao'),
      icon: BankIcon,
      action: callRelatorioIndicadoresGestaoDetalhado,
      loading: loadingIndicadoresGestaoDetalhado,
      type: 'mail',
    },
  ]

  const anoAtual = +moment().format('YYYY')
  const anos = map(range(2014, anoAtual + 2), (ano) => ({
    value: ano.toString(), label: `${(ano - 1).toString()}/${ano.toString().slice(2)}`,
  }))

  return (
    <Grid spacing={4} container>
      {RELATORIOS.map(({ sigla, action, title, icon, loading, type }) => (
        <Grid item xs={12} md={4} key={sigla}>
          <Form
            validate={validateFormValues(yup.object({
              anoBase: yup.string().required(),
              idioma: yup.number().required(),
            }))}
            onSubmit={(values) => {
              if (type === 'mail') {
                dialog.showConfirmation({
                  title: t('dialogs.relatoriosEmGeracaoTitulo'),
                  message: t('dialogs.relatoriosEmGeracao'),
                }).then((result) => {
                  if (result) {
                    dispatch(action({
                      ...values,
                      empresaId: empresa.id,
                    }))
                  }
                })
              } else {
                dispatch(action({
                  ...values,
                  empresaId: empresa.id,
                }))
              }
            }}
            render={({ handleSubmit, submitting, values }) => (
              <form onSubmit={handleSubmit}>
                <Card title={title} icon={icon}>
                  <Grid spacing={1} justifyContent="center" container>
                    <Grid item xs={6}>
                      <FieldSelect
                        required
                        name="anoBase"
                        label={t('inputs.anoBase')}
                        options={anos}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FieldSelect
                        required
                        name="idioma"
                        label={t('inputs.idioma')}
                        options={idiomas.map((idioma) => ({
                          label: t(idioma.label),
                          value: idioma.id,
                        }))}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        loading={loading}
                        loadingText="inputs.gerandoRelatorio"
                        type="submit"
                        className={classes.submitButton}
                        disabled={!values.anoBase || !values.idioma || submitting}
                      >
                        {t('relatorios.downloadRelatorio')}
                      </Button>
                    </Grid>
                  </Grid>
                </Card>
              </form>
            )}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default RelatoriosDetalhados
