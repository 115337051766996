import { API_ENDPOINT } from 'env'
import { asApiTypesObject } from 'redux-foundry'
import apiAction, { apiActionTypes } from './apiAction'

const fetchUsuarioEmpresasAuditoriasActionTypes = apiActionTypes('auditoria/API_LISTAR_USUARIO_EMPRESAS_AUDITORIAS')
const fetchDadosAuditoriaActionTypes = apiActionTypes('auditoria/API_LISTAR_DADOS_AUDITORIAS')
const salvarDadosAuditoriaActionTypes = apiActionTypes('auditoria/API_SALVAR_DADOS_AUDITORIA')
const salvarDadosParecerPressaoActionTypes = apiActionTypes('auditoria/API_SALVAR_PARECER_PRESSAO')
const salvarDadosParecerDesempenhoActionTypes = apiActionTypes('auditoria/API_SALVAR_PARECER_DESEMPENHO')
const salvarDadosParecerIndicadorActionTypes = apiActionTypes('auditoria/API_SALVAR_PARECER_INDICADOR')
const listarUnidadesEmpresaActionTypes = apiActionTypes('auditoria/API_LISTAR_UNIDADES_EMPRESA')

export const API_LISTAR_USUARIO_EMPRESAS_AUDITORIAS =
  asApiTypesObject(fetchUsuarioEmpresasAuditoriasActionTypes)
export const API_LISTAR_DADOS_AUDITORIAS = asApiTypesObject(fetchDadosAuditoriaActionTypes)
export const API_LISTAR_UNIDADES_EMPRESA = asApiTypesObject(listarUnidadesEmpresaActionTypes)
export const API_SALVAR_DADOS_AUDITORIA = asApiTypesObject(salvarDadosAuditoriaActionTypes)
export const API_SALVAR_PARECER_PRESSAO = asApiTypesObject(salvarDadosParecerPressaoActionTypes)
export const API_SALVAR_PARECER_DESEMPENHO =
  asApiTypesObject(salvarDadosParecerDesempenhoActionTypes)
export const API_SALVAR_PARECER_INDICADOR = asApiTypesObject(salvarDadosParecerIndicadorActionTypes)

export const apiListarUsuarioEmpresasAuditorias = ({ usuarioId }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/auditor/listarUsuarioEmpresasAuditorias`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchUsuarioEmpresasAuditoriasActionTypes,
}, { ...meta, query: { usuarioId } })

export const apiListarDadosAuditoria = ({ empresaId, ano }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/auditor/listarDadosAuditoria`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchDadosAuditoriaActionTypes,
}, { ...meta, query: { empresaId, ano } })

export const apiListarDadosEmpresaParaAuditoria = ({ empresaId, ano }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/auditor/listarDadosEmpresaParaAuditoria`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: listarUnidadesEmpresaActionTypes,
}, { ...meta, query: { empresaId, ano } })

export const apiSalvarDadosAuditoria = (payload, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/auditor`,
  method: 'POST',
  types: salvarDadosAuditoriaActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(payload),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiSalvarParecerPressao = (payload, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/auditor/salvarParecerPressao`,
  method: 'POST',
  types: salvarDadosParecerPressaoActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(payload),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiSalvarParecerDesempenho = (payload, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/auditor/salvarParecerDesempenho`,
  method: 'POST',
  types: salvarDadosParecerDesempenhoActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(payload),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiSalvarParecerIndicador = (payload, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/auditor/salvarParecerIndicador`,
  method: 'POST',
  types: salvarDadosParecerIndicadorActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(payload),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiListarAuditoresEmpresa = ({ empresaId }, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/usuariosAuditor/empresa/${empresaId}`,
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
  types: fetchUsuarioEmpresasAuditoriasActionTypes,
}, { ...meta, query: { empresaId } })

export const apiSalvarEditarAuditor = (payload, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/auditor/editarParecerDesempenho`,
  method: 'POST',
  types: salvarDadosParecerDesempenhoActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(payload),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiSalvarEditarAuditorPressao = (payload, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/auditor/editarParecerPressao`,
  method: 'POST',
  types: salvarDadosParecerPressaoActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(payload),
}, { ...meta, successNotification: 'mensagens.criado' })

export const apiSalvarEditarAuditorIndicadores = (payload, meta = {}) => apiAction({
  endpoint: `${API_ENDPOINT}/auditor/editarParecerIndicador`,
  method: 'POST',
  types: salvarDadosParecerIndicadorActionTypes,
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(payload),
}, { ...meta, successNotification: 'mensagens.criado' })
