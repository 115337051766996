import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PageTitle from 'components/PageTitle'
import { makeStyles, Typography } from '@material-ui/core'
import ContentBox from 'components/ContentBox'
import Button from 'components/Button'
import { useSelector, useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { getEmpresasAuditorias } from 'store/modules/auditor/auditor.selectors'
import { callSelecionarEmpresaAudicao } from 'store/modules/auditor'
import Avatar from 'components/Avatar'

const useStyles = makeStyles((theme) => ({
  contentBox: {
    padding: '24px 32px',
    minHeight: '50vh',
  },
  withoutResults: {
    fontSize: '32px',
    fontWeight: '400',
    color: '#C9CEDB',
    padding: '30px 0',
    textAlign: 'center',
  },
  boxCards: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    columnGap: '32px',
    rowGap: '48px',
    boxSizing: 'border-box',
  },
  card: {
    background: 'linear-gradient(180deg, #C9CEDB 0%, #FFFFFF 59.9%)',
    boxShadow: '0px 8px 32px rgba(15, 52, 68, 0.08)',
    borderRadius: '8px',
    padding: '16px',
    width: 'calc(100% - 32px)',
  },
  titleCard: {
    fontSize: '20px',
    fontWeight: '400',
    color: '#0F3444',
    fontFamily: theme.typography.fontFamily,
  },
  controlButton: {
    padding: '16px 0 8px 0',
    marginTop: '8px',
    display: 'flex',
    justifyContent: 'center',
  },
  linkButton: {
    textDecoration: 'none',
  },
}))

const ListaAuditorias = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()

  const listaAuditorias = useSelector(getEmpresasAuditorias)
  const [loading, setLoading] = useState(true)

  const iniciarAuditoria = (empresa) => {
    dispatch(callSelecionarEmpresaAudicao(empresa))
  }

  useEffect(() => {
    if (listaAuditorias) {
      setLoading(false)
    }
  }, [listaAuditorias])

  const renderContent = () => {
    if (loading) {
      return (
        <Typography component="h1" className={classes.withoutResults}>
          {t('auditorias.carregandoDados')}
        </Typography>
      )
    }

    if (listaAuditorias.length > 0) {
      return (
        <div className={classes.boxCards}>
          {listaAuditorias.map((empresa, index) => (
            <div key={index} className={classes.card}>
              <Avatar
                initials={empresa?.nome}
                image={empresa?.avatar}
                size="default"
              />
              <Typography component="h2" className={classes.titleCard}>
                {empresa.nome}
              </Typography>
              <div className={classes.controlButton}>
                <NavLink
                  exact
                  to="/auditoria/dadosGerais"
                  className={classes.linkButton}
                >
                  <Button variant="green" onClick={() => iniciarAuditoria(empresa)}>
                    {t('inputs.auditarEmpresa')}
                  </Button>
                </NavLink>
              </div>
            </div>
          ))}
        </div>
      )
    }

    return (
      <Typography component="h1" className={classes.withoutResults}>
        {t('auditorias.voceNaoPossuiEmpresasParaAuditar')}
      </Typography>
    )
  }

  return (
    <>
      <PageTitle title={t('auditorias.auditorias')} />
      <ContentBox className={classes.contentBox}>
        {renderContent()}
      </ContentBox>
    </>
  )
}

export default ListaAuditorias
