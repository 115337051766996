import React, { useEffect, useState } from 'react'
import ContentBox from 'components/ContentBox'
import { FormControl, InputLabel, makeStyles, MenuItem, OutlinedInput as MuiOutlinedInput, Select, Typography, withStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { map, range } from 'lodash'
import moment from 'moment'
import { saveAs } from 'file-saver'
import { useSelector, useDispatch } from 'react-redux'
import FileList3LineIcon from 'remixicon-react/FileList3LineIcon'
import DownloadLineIcon from 'remixicon-react/Download2LineIcon'
import FilePdfLineIcon from 'remixicon-react/FilePdfLineIcon'
import { getEmpresaUsuario } from 'store/modules/auth/auth.selectors'
import { getDadosAuditoriaSelecionada, getAnoSelecionado } from 'store/modules/auditor/auditor.selectors'
import { callListarDadosAuditoria, changeAnoSelecionado } from 'store/modules/auditor'

const useStyles = makeStyles((theme) => ({
  contentBox: {
    padding: theme.spacing(1, 2),
  },
  panel: {
    padding: theme.spacing(4, 2),
  },
  contentHeader: {
    display: 'flex',
    columnGap: '25px',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '48px',
  },
  contentTitle: {
    display: 'flex',
    columnGap: '25px',
    alignItems: 'center',
  },
  title: {
    fontSize: 32,
    flexShrink: 0,
    color: theme.palette.common.white,
  },
  formControl: {
    height: 60,
    width: 260,
    marginLeft: theme.spacing(1),
    '& .MuiFormLabel-root': {
      color: theme.palette.white.main,
      fontSize: theme.typography.pxToRem(14),
    },
    '& .MuiInputBase-root:hover': {
      color: theme.palette.white.main,
    },
  },
  select: {
    color: theme.palette.white.main,
  },
  iconSelect: {
    color: theme.palette.white.main,
  },
  withoutResults: {
    fontSize: '32px',
    fontWeight: '400',
    color: '#C9CEDB',
    textAlign: 'center',
    padding: '15vh 0',
  },
  viewDatasForm: {
    border: '2px solid #C5E8F7',
    marginTop: '48px',
    borderRadius: '8px',
    background: 'linear-gradient(180deg, #C5E8F7 0%, #FFFFFF 59.9%)',
    padding: '16px',
  },
  wrapper: {
    display: 'flex',
    columnGap: '24px',
  },
  titleInfos: {
    display: 'flex',
    alignItems: 'center',
    color: '#31ADE3',
    fontSize: theme.typography.pxToRem(20),
  },
  icon: {
    width: 48,
    height: 48,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.common.white,
  },
  descriptionInfo: {
    color: '#0F3444',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: '22px',
    margin: '24px 0',
  },
  fileDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  fileName: {
    color: theme.palette.primary.main,
    fontSize: 12,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginLeft: 10,
  },
  iconsDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  downloadIcon: {
    color: theme.palette.primary.main,
    marginRight: 20,
    '&:hover': {
      color: theme.palette.hover.lightBlue,
      cursor: 'pointer',
    },
  },
}))

const OutlinedInput = withStyles((theme) => ({
  root: {
    '& $notchedOutline': {
      borderColor: theme.palette.white.main,
    },
    '&:hover $notchedOutline': {
      borderColor: theme.palette.white.main,
    },
    '&$focused $notchedOutline': {
      borderColor: theme.palette.white.main,
    },
  },
  focused: {},
  notchedOutline: {},
}))(MuiOutlinedInput)

const anoAtual = +moment().format('YYYY')
const anos = map(range(2014, anoAtual + 2), (ano) => ({
  value: ano.toString(), label: `${(ano - 1).toString()}/${ano.toString().slice(2)}`,
}))

const PlanoAuditoria = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()

  const dadosAuditoria = useSelector(getDadosAuditoriaSelecionada)
  const empresa = useSelector(getEmpresaUsuario)
  const anoSelecionadoReducer = useSelector(getAnoSelecionado)

  const [anoSelecionado, setAnoSelecionado] = useState(anoSelecionadoReducer || anoAtual.toString())

  useEffect(() => {
    dispatch(callListarDadosAuditoria({
      empresaId: empresa.id, ano: anoSelecionado,
    }))
  }, [anoSelecionado, dispatch, empresa.id])

  const handleChangeAno = (event) => {
    setAnoSelecionado(event.target.value)
    dispatch(changeAnoSelecionado(event.target.value))
  }

  return (
    <>
      <div className={classes.contentHeader}>
        <Typography className={classes.title} variant="h1">
          {t('secaoAuditoria.planoAuditoria')}
        </Typography>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor="ano-base">
            {t('pressaoBiodiversidade.selecioneAno')}
          </InputLabel>
          <Select
            classes={{
              select: classes.select,
              icon: classes.iconSelect,
            }}
            value={anoSelecionado}
            onChange={handleChangeAno}
            label={t('pressaoBiodiversidade.selecioneAno')}
            inputProps={{
              name: 'ano-base',
              id: 'ano-base',
            }}
            input={<OutlinedInput label={t('pressaoBiodiversidade.selecioneAno')} />}
          >
            {map(anos, (option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <ContentBox className={classes.contentBox}>
        {dadosAuditoria?.planoSalvo ? (
          <>
            <div className={classes.viewDatasForm}>
              <div className={classes.wrapper}>
                <div className={classes.icon}>
                  <FileList3LineIcon color="#31ADE3" />
                </div>
                <Typography className={classes.titleInfos}>
                  {dadosAuditoria.nomePlano}
                </Typography>
              </div>
              <Typography className={classes.descriptionInfo}>
                {dadosAuditoria.descricaoPlano}
              </Typography>
              <div className={classes.fileDiv}>
                <div className={classes.fileDiv}>
                  <FilePdfLineIcon />
                  <p className={classes.fileName}>
                    {dadosAuditoria.nomeAnexo}
                  </p>
                </div>
                <div className={classes.iconsDiv}>
                  <DownloadLineIcon
                    className={classes.downloadIcon}
                    onClick={() => saveAs(dadosAuditoria.anexoPlano, dadosAuditoria.nomeAnexo)}
                  />
                </div>
              </div>
            </div>
          </>

        ) : (

          <Typography component="h1" className={classes.withoutResults}>
            {t('secaoAuditoria.seuAuditorAindaNaoEnviouPlanoAuditoria')}
          </Typography>
        )}
      </ContentBox>

    </>
  )
}

export default PlanoAuditoria
